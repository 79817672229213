import React from "react";
import InfoContactForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";

const subscriberAwsProductCheatSheetTag =  process.env.GATSBY_SUBSCRIBER_LP_AWS_PRODUCT_CHEAT_SHEET_TAG;


const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h3>AWS Product Cheat Sheet</h3>
        <h1>
          Learn the right tools for the Job: Download your AWS Cheat Sheet
        </h1>
        <p>
          It can be hard to decide exactly which AWS tools might be right for
          the job you are working on, let this cheat sheet help get the process
          started.
          <br />
        </p>
        <p>Remember:</p>
        <ul className="list-unstyled d-flex flex-wrap">
          <li className="d-flex align-items-center mb-3">
            <svg
              className="icon flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="46.038"
              height="45.562"
              viewBox="0 0 46.038 45.562"
            >
              <g transform="translate(-0.962 -0.99)">
                <path
                  d="M5.728,11.764a21.922,21.922,0,0,0-3.29,16.59"
                  fill="none"
                  stroke="#92c954"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M34,4.425A22,22,0,0,0,8.912,8"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M45.55,19.661A21.9,21.9,0,0,0,38,7.052"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M46,24.45a22.091,22.091,0,0,0-.434-4.791"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M2.45,28.352q.1.474.21.938"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M18.317,45.255A24.718,24.718,0,0,0,24,24.006"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M24.7,45.987c4.451-6.5,6.47-15.821,4.693-23.067a5.346,5.346,0,0,0-6.484-4.315,5.428,5.428,0,0,0-4.295,6.488,19.287,19.287,0,0,1-5.734,17.885"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M13.465,28A13.773,13.773,0,0,1,8.442,39.554"
                  fill="none"
                  stroke="#92c954"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M35.37,26a36.1,36.1,0,0,0-.591-4.167h0a11.005,11.005,0,0,0-21.743,1.131"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M31.884,44.535A35.634,35.634,0,0,0,35.431,31"
                  fill="none"
                  stroke="#92c954"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M18.96,8.3A16.5,16.5,0,0,0,7.823,27.268l.009,0A8.263,8.263,0,0,1,5.067,35.21"
                  fill="none"
                  stroke="#111"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  d="M39.6,39.5a41.076,41.076,0,0,0,.564-18.751h0A16.5,16.5,0,0,0,24,7.507"
                  fill="none"
                  stroke="#92c954"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
              </g>
            </svg>
            <p>Be specific about your needs</p>
          </li>
          <li className="d-flex align-items-center mb-3">
            <svg
              className="icon flex-shrink-0 mr-4"
              id="b571dc4d-d14c-4f83-a804-3863a81da0be"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144 144"
            >
              <g id="abd4ad87-a2ed-45f2-bbf6-98aba4eca839" data-name="n-check">
                <path
                  id="f011f813-6dfa-4694-94cb-c93bc4ba4691"
                  data-name="Path 403"
                  d="M116.7,53.71A52.8,52.8,0,1,1,67.94,21.2a53.4,53.4,0,0,1,28.24,8.13"
                  fill="none"
                  stroke="currentColor"
                  stroke-miterlimit="10"
                  stroke-width="3"
                />
                <path
                  id="a43fd21c-5dc8-491d-ae36-dcd02932ef2e"
                  data-name="Path 404"
                  d="M47.63,57.77,67.94,78.09l60.95-60.95"
                  fill="none"
                  stroke="#76bc21"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="3"
                />
              </g>
            </svg>
            <p>Let the tools work for you</p>
          </li>
        </ul>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6">
        <h3 className="text-center text-white">
          Which AWS Services are right for you?
        </h3>
        <h4 className="text-center">This Cheat Sheet will help you decide</h4>
        <div className="bg-white p-4 mt-3">
          <InfoContactForm
            location={location}
            onSubmitSuccess={()=> navigate('/lp/ty-aws-product-cheat-sheet')}
            tags={[subscriberAwsProductCheatSheetTag]}
            buttonText="GET AWS CHEAT SHEET" />
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>What our clients are saying about us</h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="The 729 Solutions team possesses a very deep understanding of software development and agile methodologies."
            name="NOOPUR SHANKAR"
            company="Optimal Strategix Group, Inc."
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ( {location}) => (
  <Layout>
    <Main location={location}/>
    <Testimonial />
  </Layout>
);

export default Index;
